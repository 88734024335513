import React, { useEffect, useState } from 'react';
import { db } from '../Firebase'; // Ensure this points to your Firebase configuration file
import { collection, getDocs, doc, deleteDoc, query, orderBy, onSnapshot, updateDoc } from 'firebase/firestore';
import { formatToUSDate } from '../utils/dateUtils';
import DetailView from './DetailView';
import jsPDF from 'jspdf';

const ClientCards = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        const clientsQuery = query(collection(db, "insuranceForms"), orderBy("submittedAt", "desc"));
        const unsubscribe = onSnapshot(clientsQuery, (snapshot) => {
            // Check for new documents and send email notifications
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const data = change.doc.data();
                    const submittedAt = data.submittedAt?.toDate();
                    // Check if the document was created in the last minute
                    if (submittedAt && (new Date().getTime() - submittedAt.getTime()) < 60000) {
                        // Send email notification for new submissions
                        sendEmailNotification(data);
                    }
                }
            });

            // Update state with all documents
            const clientsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setClients(clientsData);
        });

        return () => unsubscribe();
    }, []);

    const sendEmailNotification = async (formData) => {
        try {
            const emailContent = {
                subject: "New Personal Auto Insurance Application",
                timestamp: new Date().toLocaleString(),
                personalInfo: {
                    name: `${formData.firstName} ${formData.lastName}`,
                    email: formData.email,
                    phone: formData.phone,
                    address: formData.address,
                    city: formData.city,
                    state: formData.state,
                    zipCode: formData.zipCode
                },
                vehicles: formData.carDetails?.map((car, index) => ({
                    number: index + 1,
                    make: car.carMake,
                    model: car.carModel,
                    year: car.carYear,
                    vin: car.vinNumber
                })) || [],
                coverageInfo: {
                    hasInsurance: formData.coverageData?.hasInsurance || 'N/A',
                    insuranceProvider: formData.coverageData?.insuranceName || 'N/A',
                    insuranceDuration: formData.coverageData?.insuranceDuration || 'N/A',
                    coverageType: formData.coverageData?.bodyInjuryPropertyDamage || 'N/A',
                    deductible: formData.coverageData?.comprehensiveCollisionDeductible || 'N/A'
                }
            };

            // Send immediately without waiting for response
            fetch('https://backend-birimo.vercel.app/api/notify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    formType: "Personal Auto",
                    formData: emailContent
                })
            }).catch(error => console.error('Error sending notification:', error));
        } catch (error) {
            console.error('Error preparing notification:', error);
        }
    };

    const deleteClient = async (clientId) => {
        await deleteDoc(doc(db, "insuranceForms", clientId));
        // Removing the deleted client from the state
        setClients(clients.filter(client => client.id !== clientId));
    };

    const formatKey = (key) => {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    };

    const hasData = (data) => {
        return data && Object.keys(data).length > 0 && Object.values(data).some(x => x !== null && x !== '');
    };

    const renderPersonalDetails = (client) => {
        const personalDetailsKeys = [
            'firstName', 'lastName', 'dob', 'maritalStatus', 'dlNumber', 'dlState', 'dlStatus',
            'phone', 'email', 'address', 'apartment', 'city', 'state', 'zipCode', 'gender',
            'addressDuration', 'occupation', 'educationLevel',
            'spouseName', 'spouseDlNumber', 'spouseDlStatus', 'spouseDlState', 'spouseDob'
        ];

        return personalDetailsKeys.map(key => {
            if (!client[key]) return null;

            let displayValue = client[key];
            
            switch(key) {
                case 'addressDuration':
                    return <p key={key} className="text-sm">Time at Current Address: {displayValue}</p>;
                case 'occupation':
                    return <p key={key} className="text-sm">Occupation: {displayValue}</p>;
                case 'educationLevel':
                    return <p key={key} className="text-sm">Education Level: {displayValue}</p>;
                case 'spouseName':
                    return <p key={key} className="text-sm">Spouse Name: {displayValue}</p>;
                case 'spouseDob':
                    return <p key={key} className="text-sm">Spouse Date of Birth: {displayValue}</p>;
                case 'spouseDlNumber':
                    return <p key={key} className="text-sm">Spouse DL Number: {displayValue}</p>;
                case 'spouseDlState':
                    return <p key={key} className="text-sm">Spouse DL State: {displayValue}</p>;
                case 'spouseDlStatus':
                    return <p key={key} className="text-sm">Spouse DL Status: {displayValue}</p>;
                default:
                    return <p key={key} className="text-sm">{`${formatKey(key)}: ${displayValue}`}</p>;
            }
        }).filter(Boolean);
    };

    const renderCarDetails = (cars) => {
        return cars.map((car, index) => (
            <div key={index} className="mt-2">
                <h4 className="text-lg font-semibold">Car {index + 1}</h4>
                {Object.entries(car).map(([key, value]) => {
                    switch(key) {
                        case 'coSigner':
                            return <p key={key} className="text-sm">Co-signer: {value}</p>;
                        case 'vehicleDuration':
                            return <p key={key} className="text-sm">Time with Vehicle: {value}</p>;
                        case 'vehicleUse':
                            return <p key={key} className="text-sm">Vehicle Use: {value}</p>;
                        case 'carOwnership':
                            return <p key={key} className="text-sm">Ownership Status: {value}</p>;
                        default:
                            return <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>;
                    }
                })}
            </div>
        ));
    };

    const renderHouseholdMembers = (members) => {
        return members.map((member, index) => (
            <div key={index} className="mt-2">
                <h4 className="text-lg font-semibold">Household Member {index + 1}</h4>
                {Object.entries(member).map(([key, value]) => (
                    <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>
                ))}
            </div>
        ));
    };

    const renderCoverageSection = (coverage) => {
        if (!coverage) return null;

        const coverageFields = {
            hasInsurance: 'Has Insurance',
            priorInsuranceCarrier: 'Prior Insurance Carrier',
            insuranceName: 'Insurance Name',
            insuranceDuration: 'Insurance Duration',
            bodyInjuryPropertyDamage: 'Body Injury & Property Damage',
            comprehensiveCollisionDeductible: 'Comprehensive & Collision Deductible',
            fullGlassCoverage: 'Full Glass Coverage',
            needTowing: 'Towing Coverage',
            needRentalCar: 'Rental Car Coverage'
        };

        return (
            <div className="mt-4">
                <h4 className="text-lg font-semibold">Coverage Information</h4>
                {Object.entries(coverage).map(([key, value]) => {
                    if (!value) return null;
                    return (
                        <p key={key} className="text-sm">
                            {coverageFields[key] || formatKey(key)}: {value}
                        </p>
                    );
                })}
            </div>
        );
    };

    const renderSection = (client, section) => {
        if (!hasData(client[section])) {
            return null; // Don't render this section if it's empty
        }

        return (
            <div>
                <h4 className="text-lg font-semibold mt-4">{formatKey(section)}</h4>
                <div className="ml-4">
                    {section === 'carDetails' ? renderCarDetails(client[section]) : 
                     section === 'householdMembers' ? renderHouseholdMembers(client[section]) : 
                     Object.entries(client[section]).map(([key, value]) => (
                        <p key={key} className="text-sm">{`${formatKey(key)}: ${value}`}</p>
                    ))}
                </div>
            </div>
        );
    };

    const formatSubmissionDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return formatToUSDate(timestamp.toDate());
    };

    const markAsSeen = async (clientId) => {
        try {
            await updateDoc(doc(db, "insuranceForms", clientId), {
                seen: true,
                seenAt: new Date()
            });
        } catch (error) {
            console.error("Error marking as seen:", error);
        }
    };

    const handleCardClick = (client) => {
        setSelectedClient(client);
        if (!client.seen) {
            markAsSeen(client.id);
        }
    };

    // Function to generate and download PDF of client details
    const generatePDF = (e, client) => {
        e.stopPropagation(); // Prevent card click event
        
        const doc = new jsPDF();
        let yPos = 20;
        const lineHeight = 7;
        const margin = 15;
        
        // Title
        doc.setFontSize(16);
        doc.text('Personal Auto Insurance Application', margin, yPos);
        yPos += lineHeight * 2;
        
        // Personal Details
        doc.setFontSize(14);
        doc.text('Personal Details', margin, yPos);
        yPos += lineHeight;
        doc.setFontSize(10);
        
        // Filter for personal details (excluding license and spouse info)
        const personalKeys = [
            'firstName', 'lastName', 'dob', 'maritalStatus', 'gender',
            'phone', 'email', 'address', 'apartment', 'city', 'state', 'zipCode',
            'addressDuration', 'occupation', 'educationLevel'
        ];

        // Display name first
        if (client.firstName) {
            doc.text(`Name: ${client.firstName} ${client.lastName || ''}`, margin, yPos);
            yPos += lineHeight;
        }
        
        // Then go through other personal details
        personalKeys.forEach(key => {
            if (key !== 'firstName' && key !== 'lastName' && client[key]) {
                let label;
                let value = client[key];
                
                switch(key) {
                    case 'dob':
                        label = 'Date of Birth';
                        break;
                    case 'addressDuration':
                        label = 'Time at Current Address';
                        break;
                    default:
                        label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
                }
                
                doc.text(`${label}: ${value}`, margin, yPos);
                yPos += lineHeight;
                
                // Add a page if we're close to the bottom
                if (yPos > 270) {
                    doc.addPage();
                    yPos = 20;
                }
            }
        });
        
        // Address formatting if needed
        if (!client.address && (client.city || client.state || client.zipCode)) {
            doc.text(`${client.city || ''}, ${client.state || ''} ${client.zipCode || ''}`, margin, yPos);
            yPos += lineHeight;
        }
        
        // Driver License Information Section
        if (client.dlNumber || client.dlState || client.dlStatus) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Driver License Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            if (client.dlNumber) {
                doc.text(`Driver License Number: ${client.dlNumber}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.dlState) {
                doc.text(`Driver License State: ${client.dlState}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.dlStatus) {
                doc.text(`Driver License Status: ${client.dlStatus}`, margin, yPos);
                yPos += lineHeight;
            }
            
            // Add a page if we're close to the bottom
            if (yPos > 270) {
                doc.addPage();
                yPos = 20;
            }
        }
        
        // Spouse Information Section
        if (client.spouseName || client.spouseDob || client.spouseDlNumber || client.spouseDlState || client.spouseDlStatus) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Spouse Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            if (client.spouseName) {
                doc.text(`Spouse Name: ${client.spouseName}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.spouseDob) {
                doc.text(`Spouse Date of Birth: ${client.spouseDob}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.spouseDlNumber) {
                doc.text(`Spouse Driver License Number: ${client.spouseDlNumber}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.spouseDlState) {
                doc.text(`Spouse Driver License State: ${client.spouseDlState}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (client.spouseDlStatus) {
                doc.text(`Spouse Driver License Status: ${client.spouseDlStatus}`, margin, yPos);
                yPos += lineHeight;
            }
            
            // Add a page if we're close to the bottom
            if (yPos > 270) {
                doc.addPage();
                yPos = 20;
            }
        }
        
        // Car Details
        if (client.carDetails && client.carDetails.length > 0) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Vehicle Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            client.carDetails.forEach((car, index) => {
                doc.text(`Vehicle ${index + 1}:`, margin, yPos);
                yPos += lineHeight;
                
                // Display all car details
                Object.entries(car).forEach(([key, value]) => {
                    if (value) {
                        let label;
                        
                        switch(key) {
                            case 'carMake':
                                label = 'Make';
                                break;
                            case 'carModel':
                                label = 'Model';
                                break;
                            case 'carYear':
                                label = 'Year';
                                break;
                            case 'vinNumber':
                                label = 'VIN';
                                break;
                            case 'vehicleUse':
                                label = 'Usage';
                                break;
                            case 'coSigner':
                                label = 'Co-signer';
                                break;
                            case 'vehicleDuration':
                                label = 'Time with Vehicle';
                                break;
                            case 'carOwnership':
                                label = 'Ownership Status';
                                break;
                            default:
                                label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
                        }
                        
                        doc.text(`${label}: ${value}`, margin + 5, yPos);
                        yPos += lineHeight;
                        
                        // Add a page if we're close to the bottom
                        if (yPos > 270) {
                            doc.addPage();
                            yPos = 20;
                        }
                    }
                });
                
                // Add a bit of space between vehicles
                yPos += lineHeight/2;
                
                // Add a page if we're close to the bottom
                if (yPos > 270) {
                    doc.addPage();
                    yPos = 20;
                }
            });
        }
        
        // Household Members
        if (client.householdMembers && client.householdMembers.length > 0) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Household Members', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            client.householdMembers.forEach((member, index) => {
                doc.text(`Member ${index + 1}:`, margin, yPos);
                yPos += lineHeight;
                
                // Display all member details
                Object.entries(member).forEach(([key, value]) => {
                    if (value) {
                        const label = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
                        doc.text(`${label}: ${value}`, margin + 5, yPos);
                        yPos += lineHeight;
                        
                        // Add a page if we're close to the bottom
                        if (yPos > 270) {
                            doc.addPage();
                            yPos = 20;
                        }
                    }
                });
                
                // Add a bit of space between members
                yPos += lineHeight/2;
                
                // Add a page if we're close to the bottom
                if (yPos > 270) {
                    doc.addPage();
                    yPos = 20;
                }
            });
        }
        
        // Coverage Information
        if (client.coverageData) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Coverage Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            const coverage = client.coverageData;
            const coverageFields = {
                hasInsurance: 'Has Insurance',
                priorInsuranceCarrier: 'Prior Insurance Carrier',
                insuranceName: 'Insurance Name',
                insuranceDuration: 'Insurance Duration',
                bodyInjuryPropertyDamage: 'Body Injury & Property Damage',
                comprehensiveCollisionDeductible: 'Comprehensive & Collision Deductible',
                fullGlassCoverage: 'Full Glass Coverage',
                needTowing: 'Towing Coverage',
                needRentalCar: 'Rental Car Coverage'
            };
            
            // Display all coverage details
            Object.entries(coverage).forEach(([key, value]) => {
                if (value) {
                    const label = coverageFields[key] || key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
                    doc.text(`${label}: ${value}`, margin, yPos);
                    yPos += lineHeight;
                    
                    // Add a page if we're close to the bottom
                    if (yPos > 270) {
                        doc.addPage();
                        yPos = 20;
                    }
                }
            });
        }
        
        // Add submission timestamp
        if (client.submittedAt) {
            yPos += lineHeight;
            doc.text(`Submission Date: ${formatSubmissionDate(client.submittedAt)}`, margin, yPos);
        }
        
        // Generate filename and save
        const filename = `${client.firstName || 'Client'}_${client.lastName || ''}_Auto_Insurance.pdf`;
        doc.save(filename);
    };

    const renderSummaryCard = (client) => (
        <div 
            key={client.id} 
            className={`bg-white rounded-lg shadow-md p-6 cursor-pointer hover:shadow-lg transition-shadow relative ${
                !client.seen ? 'border-l-4 border-blue-500' : ''
            }`}
            onClick={() => handleCardClick(client)}
        >
            {!client.seen && (
                <div className="absolute top-2 right-2">
                    <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                        New
                    </span>
                </div>
            )}
            
            <div className="flex justify-between items-start">
                <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                        {client.firstName} {client.lastName}
                    </h3>
                    <p className="text-sm text-gray-500">{client.email}</p>
                    <p className="text-sm text-gray-500">{client.phone}</p>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={(e) => generatePDF(e, client)}
                        className="text-blue-500 hover:text-blue-700"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteClient(client.id);
                        }}
                        className="text-red-500 hover:text-red-700"
                    >
                        <img src='/images/delete.png' className="w-[20px] h-[20px]" alt="Delete" />
                    </button>
                </div>
            </div>
            
            <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                <div>
                    <span className="text-gray-500">Vehicles:</span>
                    <span className="ml-2 text-gray-900">{client.carDetails?.length || 0}</span>
                </div>
                <div>
                    <span className="text-gray-500">Status:</span>
                    <span className="ml-2 text-green-600">Active</span>
                </div>
            </div>
            
            <div className="mt-4 text-xs text-gray-500">
                Submitted: {formatToUSDate(client.submittedAt?.toDate())}
            </div>
        </div>
    );

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {clients.map(renderSummaryCard)}
            </div>

            {selectedClient && (
                <DetailView 
                    data={selectedClient} 
                    onClose={() => setSelectedClient(null)} 
                />
            )}
        </>
    );
};

export default ClientCards;
