import React, { useState } from 'react';
import { getTodayDate } from '../utils/dateUtils';

const HouseholdMemberForm = ({ householdMembers, setHouseholdMembers, onSubmit, usStates }) => {
  
    const [errors, setErrors] = useState({});
    const [totalHouseholdMembers, setTotalHouseholdMembers] = useState('');

    

    const handleHouseholdChange = (index, e) => {
        const updatedMembers = [...householdMembers];
        updatedMembers[index][e.target.name] = e.target.value;
        setHouseholdMembers(updatedMembers);
    };

    const addHouseholdMember = () => {
        setHouseholdMembers([...householdMembers, { firstName: '', lastName: '', dob: '', gender: '', dlNumber: '', dlState: '', relationship: '' }]);
    };

    const validateSpouseAndHousehold = () => {
        let tempErrors = {};
        householdMembers.forEach((member, index) => {
            if (!member.firstName) tempErrors[`firstName-${index}`] = 'First name is required';
            if (!member.lastName) tempErrors[`lastName-${index}`] = 'Last name is required';
            if (!member.dob) tempErrors[`dob-${index}`] = 'Date of birth is required';
            if (!member.gender) tempErrors[`gender-${index}`] = 'Gender is required';
            if (!member.dlNumber) tempErrors[`dlNumber-${index}`] = "Driver's License Number is required";
            if (!member.dlState) tempErrors[`dlState-${index}`] = "Driver's License State is required";
            if (!member.relationship) tempErrors[`relationship-${index}`] = 'Relationship to insured is required';
        });

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateSpouseAndHousehold()) return;
        onSubmit({
            householdMembers,
            totalHouseholdMembers
        });
    };

    const removeHouseholdMember = (index) => {
        const updatedMembers = householdMembers.filter((_, idx) => idx !== index);
        setHouseholdMembers(updatedMembers);
        // Optionally handle errors state if necessary
    };
    
    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container">
            <h1 className="text-2xl font-bold mb-6 text-center">Household Member Form</h1>

           {/* Household Members */}
                     
            {householdMembers.map((member, index) => (
                <div key={index} className="mb-4">
                    {/* Household Member First Name */}
                    <div className="flex justify-between items-center my-4">
        <div className="flex-grow text-center">
            <h2 className="text-xl font-bold">Household Members</h2>
        </div>
        <button
           onClick={() => removeHouseholdMember(index)}
            className="text-red-500 font-bold"
            aria-label={`Remove car ${index + 1}`}
        >
            X
        </button>
    </div>
            
        
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`firstName-${index}`}>
                            First Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`firstName-${index}`}
                            type="text"
                            name="firstName"
                            value={member.firstName}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        />
                        {errors[`firstName-${index}`] && <p className="text-red-500 text-xs italic">{errors[`firstName-${index}`]}</p>}
                    </div>

                    {/* Household Member Last Name */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`lastName-${index}`}>
                            Last Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`lastName-${index}`}
                            type="text"
                            name="lastName"
                            value={member.lastName}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        />
                        {errors[`lastName-${index}`] && <p className="text-red-500 text-xs italic">{errors[`lastName-${index}`]}</p>}
                    </div>

                    {/* Household Member Date of Birth */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`dob-${index}`}>
                            Date of Birth
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`dob-${index}`}
                            type="date"
                            max={getTodayDate()}
                            name="dob"
                            value={member.dob}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        />
                        {errors[`dob-${index}`] && <p className="text-red-500 text-xs italic">{errors[`dob-${index}`]}</p>}
                    </div>

                    {/* Household Member Gender */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`gender-${index}`}>
                            Gender
                        </label>
                        <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`gender-${index}`}
                            name="gender"
                            value={member.gender}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        {errors[`gender-${index}`] && <p className="text-red-500 text-xs italic">{errors[`gender-${index}`]}</p>}
                    </div>

                    {/* Household Member Driver's License Number */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`dlNumber-${index}`}>
                            Driver's License Number
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`dlNumber-${index}`}
                            type="text"
                            name="dlNumber"
                            value={member.dlNumber}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        />
                        {errors[`dlNumber-${index}`] && <p className="text-red-500 text-xs italic">{errors[`dlNumber-${index}`]}</p>}
                    </div>

                    {/* Household Member Driver's License State */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`dlState-${index}`}>
                            Driver's License State
                        </label>
                        <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`dlState-${index}`}
                            name="dlState"
                            value={member.dlState}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        >
                            <option value="">Select State</option>
                            {usStates.map((state, i) => (
                                <option key={i} value={state}>{state}</option>
                            ))}
                        </select>
                        {errors[`dlState-${index}`] && <p className="text-red-500 text-xs italic">{errors[`dlState-${index}`]}</p>}
                    </div>

                    {/* Household Member Relationship to Insured */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`relationship-${index}`}>
                            Relationship to Insured
                        </label>
                        <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id={`relationship-${index}`}
                            name="relationship"
                            value={member.relationship}
                            onChange={(e) => handleHouseholdChange(index, e)}
                        >
                            <option value="">Select Relationship</option>
                            <option value="parent">Parent</option>
                            <option value="child">Child</option>
                            <option value="other">Other</option>
                        </select>
                        {errors[`relationship-${index}`] && <p className="text-red-500 text-xs italic">{errors[`relationship-${index}`]}</p>}
                    </div>
                </div>
            ))}
<div className='w-full flex flex-col items-center mb-10'>
<button type="button" onClick={addHouseholdMember} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4 mb-6">
                Add Another Household Member
            </button>

            {/* Total Household Members Question */}
            <div className="w-full mt-2 mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    How many people are living in the home? (Including children, excluded drivers, etc.)
                </label>
                <input
                    type="number"
                    min="1"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={totalHouseholdMembers}
                    onChange={(e) => setTotalHouseholdMembers(e.target.value)}
                    placeholder="Enter total number of people"
                    required
                />
            </div>

            <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-2">
                Next
            </button>
</div>
            
        </form>
    );
};

export default HouseholdMemberForm;

                           

