import React from 'react';
import { formatToUSDate } from '../utils/dateUtils';

const DetailView = ({ data, onClose }) => {
  const renderSection = (title, content) => (
    <div className="bg-white rounded-lg shadow mb-6 p-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {content}
      </div>
    </div>
  );

  const renderField = (label, value) => (
    <div className="mb-2">
      <span className="text-sm font-medium text-gray-500">{label}: </span>
      <span className="text-sm text-gray-900">{value || 'N/A'}</span>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-md bg-gray-100">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-900">Client Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <span className="text-2xl">&times;</span>
          </button>
        </div>

        <div className="max-h-[70vh] overflow-y-auto px-2">
          {/* Personal Information */}
          {renderSection("Personal Information", (
            <>
              {renderField("First Name", data.firstName)}
              {renderField("Last Name", data.lastName)}
              {renderField("Date of Birth", formatToUSDate(data.dob))}
              {renderField("Gender", data.gender)}
              {renderField("Marital Status", data.maritalStatus)}
              {renderField("Email", data.email)}
              {renderField("Phone", data.phone)}
              {renderField("Address", data.address)}
              {renderField("Apartment", data.apartment)}
              {renderField("City", data.city)}
              {renderField("State", data.state)}
              {renderField("Zip Code", data.zipCode)}
              {renderField("Address Duration", data.addressDuration)}
              {renderField("Occupation", data.occupation)}
              {renderField("Education Level", data.educationLevel)}
            </>
          ))}

          {/* Driver's License Information */}
          {renderSection("Driver's License Information", (
            <>
              {renderField("License Number", data.dlNumber)}
              {renderField("License State", data.dlState)}
              {renderField("License Status", data.dlStatus)}
            </>
          ))}

          {/* Spouse Information */}
          {data.maritalStatus?.toLowerCase() === 'married' && renderSection("Spouse Information", (
            <>
              {/* Basic Spouse Info */}
              {renderField("Spouse First Name", data.spouseDetails?.firstName || data.spouseName)}
              
              {renderField("Spouse Date of Birth", formatToUSDate(data.spouseDetails?.dob || data.spouseDob))}
              

              {/* Spouse License Info */}
              {renderField("Spouse License Number", data.spouseDetails?.dlNumber || data.spouseDlNumber)}
              {renderField("Spouse License State", data.spouseDetails?.dlState || data.spouseDlState)}
              {renderField("Spouse License Status", data.spouseDetails?.dlStatus || data.spouseDlStatus)}
            </>
          ))}

          {/* Vehicle Information */}
          {data.carDetails && data.carDetails.map((car, index) => (
            renderSection(`Vehicle ${index + 1} Information`, (
              <>
                {renderField("Has VIN", car.hasVin)}
                {renderField("Make", car.carMake)}
                {renderField("Model", car.carModel)}
                {renderField("Year", car.carYear)}
                {renderField("VIN Number", car.vinNumber)}
                {renderField("Car Ownership", car.carOwnership)}
                {renderField("Co-signer", car.coSigner)}
                {renderField("Vehicle Duration", car.vehicleDuration)}
                {renderField("Vehicle Use", car.vehicleUse)}
              </>
            ))
          ))}

          {/* Household Members */}
          {data.householdMembers && data.householdMembers.length > 0 && renderSection("Household Members", (
            <>
              {renderField("Total Household Members", data.totalHouseholdMembers)}
              {data.householdMembers.map((member, index) => (
                <div key={index} className="border-b pb-2 mb-2 last:border-b-0 col-span-2">
                  <h4 className="font-medium text-gray-900 mb-2">Member {index + 1}</h4>
                  {renderField("First Name", member.firstName)}
                  {renderField("Last Name", member.lastName)}
                  {renderField("Date of Birth", formatToUSDate(member.dob))}
                  {renderField("Gender", member.gender)}
                  {renderField("License Number", member.dlNumber)}
                  {renderField("License State", member.dlState)}
                  {renderField("Relationship", member.relationship)}
                </div>
              ))}
            </>
          ))}

          {/* Coverage Information */}
          {data.coverageData && renderSection("Coverage Information", (
            <>
              {renderField("Has Insurance", data.coverageData.hasInsurance)}
              {renderField("Insurance Provider", data.coverageData.insuranceName)}
              {renderField("Insurance Duration", data.coverageData.insuranceDuration)}
              {renderField("Body Injury & Property Damage", data.coverageData.bodyInjuryPropertyDamage)}
              {renderField("Comprehensive & Collision Deductible", data.coverageData.comprehensiveCollisionDeductible)}
              {renderField("Full Glass Coverage", data.coverageData.fullGlassCoverage)}
              {renderField("Towing Coverage", data.coverageData.needTowing)}
              {renderField("Rental Car Coverage", data.coverageData.needRentalCar)}
            </>
          ))}

          {/* Submission Information */}
          {renderSection("Submission Details", (
            <>
              {renderField("Submitted At", formatToUSDate(data.submittedAt?.toDate()))}
              {renderField("Status", data.seen ? 'Reviewed' : 'New')}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailView; 