import React, { useState } from 'react';

const CarDetailsForm = ({ onSubmit,carDetails }) => {
    const [cars, setCars] = useState(carDetails);
    const [errors, setErrors] = useState([{}]);
    const maxCars = 3;
    const [carCount, setCarCount] = useState(1);
    // Create an empty car object
    function createEmptyCar() {
        return {
            hasVin: '',
            carMake: '',
            carModel: '',
            carYear: '',
            vinNumber: '',
            carOwnership: '',
            coSigner: '',
            vehicleDuration: '',
            vehicleUse: ''
        };
    }

    // Handle input change for each car
    const handleChange = (index, e) => {
        const newCars = [...cars];
        newCars[index][e.target.name] = e.target.value;
        setCars(newCars);
    };

    // Validate a single car's details
    const validateCar = (car) => {
        let tempErrors = {};
        if (car.hasVin === '') {
            tempErrors.hasVin = "VIN number status is required";
        } else if (car.hasVin === 'yes' && car.vinNumber.length !== 17) {
            tempErrors.vinNumber = "VIN must be 17 characters long";
        }
        if (car.hasVin === 'no') {
            if (!car.carMake) tempErrors.carMake = "Car make is required";
            if (!car.carModel) tempErrors.carModel = "Car model is required";
            if (!car.carYear) tempErrors.carYear = "Car year is required";
        }
        if (!car.carOwnership) tempErrors.carOwnership = "Car ownership status is required";
        if (!car.coSigner) tempErrors.coSigner = "Co-signer information is required";
        if (!car.vehicleDuration) tempErrors.vehicleDuration = "Vehicle duration is required";
        if (!car.vehicleUse) tempErrors.vehicleUse = "Vehicle use is required";

        return tempErrors;
    };

    // Validate all cars' details
    const validateAllCars = () => {
        const allErrors = cars.map(validateCar);
        setErrors(allErrors);
        return allErrors.every(carErrors => Object.keys(carErrors).length === 0);
    };

    

    const addAnotherCar = () => {
        if (carCount < maxCars) {
            setCars([...cars, createEmptyCar()]);
            setErrors([...errors, {}]);
            setCarCount(carCount + 1); // Increment car count
        }
    };
    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateAllCars()) {
            onSubmit(cars);
        }
    };

    const removeCar = (index) => {
        const newCars = [...cars];
        newCars.splice(index, 1);
        const newErrors = [...errors];
        newErrors.splice(index, 1);
    
        setCars(newCars);
        setErrors(newErrors);
        setCarCount(carCount - 1);
    };
    

    return (
        <>
 <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container ">
            <h1 className="text-2xl font-bold mb-6 text-center">Car Details</h1>

            {cars.map((car, index) => (
                <div key={index} className="mb-4 mt-5 ">
                    {/* Ask about VIN */}
                    {/* {index > 0 && <h2 className="text-xl font-bold my-4 text-center">Add New Car</h2>} */}
                    {index > 0 && (
    <div className="flex justify-between items-center my-4">
        <div className="flex-grow text-center">
            <h2 className="text-xl font-bold">Add New Car</h2>
        </div>
        <button
            onClick={() => removeCar(index)}
            className="text-red-500 font-bold"
            aria-label={`Remove car ${index + 1}`}
        >
            X
        </button>
    </div>
)}

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`hasVin-${index}`}>
                            Do you have the VIN number?
                        </label>
                        <select 
                            id={`hasVin-${index}`}
                            name="hasVin"
                            onChange={(e) => handleChange(index, e)}
                            value={car.hasVin}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        {errors[index]?.hasVin && <p className="text-red-500 text-xs italic">{errors[index].hasVin}</p>}
                    </div>

                    {/* Conditional Fields for VIN or Car Details */}
                    {car.hasVin === 'yes' && (
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`vinNumber-${index}`}>
                                VIN Number
                            </label>
                            <input 
                                id={`vinNumber-${index}`}
                                type="text"
                                name="vinNumber"
                                placeholder="Enter VIN Number"
                                onChange={(e) => handleChange(index, e)}
                                value={car.vinNumber}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {errors[index]?.vinNumber && <p className="text-red-500 text-xs italic">{errors[index].vinNumber}</p>}
                        </div>
                    )}

                    {car.hasVin === 'no' && (
                        <>
                            {/* Car Make */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`carMake-${index}`}>
                                    Car Make
                                </label>
                                <input 
                                    id={`carMake-${index}`}
                                    type="text"
                                    name="carMake"
                                    placeholder="Enter Car Make"
                                    onChange={(e) => handleChange(index, e)}
                                    value={car.carMake}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors[index]?.carMake && <p className="text-red-500 text-xs italic">{errors[index].carMake}</p>}
                            </div>

                            {/* Car Model */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`carModel-${index}`}>
                                    Car Model
                                </label>
                                <input 
                                    id={`carModel-${index}`}
                                    type="text"
                                    name="carModel"
                                    placeholder="Enter Car Model"
                                    onChange={(e) => handleChange(index, e)}
                                    value={car.carModel}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors[index]?.carModel && <p className="text-red-500 text-xs italic">{errors[index].carModel}</p>}
                            </div>

                            {/* Car Year */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`carYear-${index}`}>
                                    Car Year
                                </label>
                                <input 
                                    id={`carYear-${index}`}
                                    type="number"
                                    name="carYear"
                                    placeholder="Enter Car Year"
                                    onChange={(e) => handleChange(index, e)}
                                    value={car.carYear}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                {errors[index]?.carYear && <p className="text-red-500 text-xs italic">{errors[index].carYear}</p>}
                            </div>
                        </>
                    )}

                    {/* Car Ownership */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`carOwnership-${index}`}>
                            Do you own, lease, or finance the car?
                        </label>
                        <select 
                            id={`carOwnership-${index}`}
                            name="carOwnership"
                            onChange={(e) => handleChange(index, e)}
                            value={car.carOwnership}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="">Select an option</option>
                            <option value="own">Own</option>
                            <option value="lease">Lease</option>
                            <option value="finance">Finance</option>
                        </select>
                        {errors[index]?.carOwnership && <p className="text-red-500 text-xs italic">{errors[index].carOwnership}</p>}
                    </div>

                    {/* Co-signer Question */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Is there a co-signer of this vehicle?
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={car.coSigner || ''}
                            name="coSigner"
                            onChange={(e) => handleChange(index, e)}
                            required
                        >
                            <option value="">Select Option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    {/* Vehicle Duration Question */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            How Long Have You Had This Vehicle?
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={car.vehicleDuration || ''}
                            name="vehicleDuration"
                            onChange={(e) => handleChange(index, e)}
                            required
                        >
                            <option value="">Select Duration</option>
                            <option value="less than 1 month">Less than 1 month</option>
                            <option value="1 month - 6 month">1 month - 6 month</option>
                            <option value="6 month - 1 year">6 month - 1 year</option>
                            <option value="1 year">1 year</option>
                            <option value="1-3 years">More than 1 year but less than 3 years</option>
                            <option value="5+ years">5 Year or more</option>
                        </select>
                    </div>

                    {/* Vehicle Use Question */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            What is the use of the vehicle?
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={car.vehicleUse || ''}
                            name="vehicleUse"
                            onChange={(e) => handleChange(index, e)}
                            required
                        >
                            <option value="">Select Usage</option>
                            <option value="personal">Personal</option>
                            <option value="commute">Commute</option>
                            <option value="business">Business</option>
                        </select>
                    </div>
                </div>
            ))}

<button disabled={carCount >= maxCars} type="button" onClick={addAnotherCar} className={`bg-${carCount >= maxCars ? 'gray-200' : 'blue-500'} hover:bg-${carCount >= maxCars ? 'gray-200' : 'blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4`}>
                Add Another Car
            </button>
            <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 ml-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4">
                Next
            </button>
        </form>

        

           
        </>
       
    );
};

export default CarDetailsForm;
