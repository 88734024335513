import React, { useState } from 'react';
import { getTodayDate } from '../utils/dateUtils';

const CoverageSectionForm = ({ onSubmit, formData, setFormData }) => {
   
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let tempErrors = {};
    
        // Validate policyEffectiveDate
        if (!formData.policyEffectiveDate.trim()) {
            tempErrors.policyEffectiveDate = 'Policy effective date is required';
        }
    
        // Validate hasRenterInsurance
        if (!formData.hasRenterInsurance.trim()) {
            tempErrors.hasRenterInsurance = 'Please indicate if you have renter insurance';
        }
    
        // Validate insuranceCompany if hasRenterInsurance is 'yes'
        if (formData.hasRenterInsurance === 'yes' && !formData.insuranceCompany.trim()) {
            tempErrors.insuranceCompany = 'Insurance company name is required';
        }
    
        // Validate personalPropertyCoverage
        if (!formData.personalPropertyCoverage.trim()) {
            tempErrors.personalPropertyCoverage = 'Please select a personal property coverage amount';
        }
    
        // Validate liabilityCoverage
        if (!formData.liabilityCoverage.trim()) {
            tempErrors.liabilityCoverage = 'Please select a liability coverage amount';
        }
    
        // Validate waterBackupCoverage
        if (!formData.waterBackupCoverage.trim()) {
            tempErrors.waterBackupCoverage = 'Please indicate if you want to add water backup coverage';
        }
    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            onSubmit(formData);
            
        }
        
    };


    return (
        
            <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container">
                {/* Policy Effective Date */}
                <div className="mb-4">
                    <label htmlFor="policyEffectiveDate" className="block text-gray-700 text-sm font-bold mb-2">
                        Choose Policy Effective Date
                    </label>
                    <input
                        id="policyEffectiveDate"
                        name="policyEffectiveDate"
                        type="date"
                        min={getTodayDate()}
                        onChange={handleChange}
                        value={formData.policyEffectiveDate}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    />
                    {errors.policyEffectiveDate && <p className="text-red-500 text-xs italic">{errors.policyEffectiveDate}</p>}
                </div>

                {/* Renter Insurance */}
                <div className="mb-4">
                    <label htmlFor="hasRenterInsurance" className="block text-gray-700 text-sm font-bold mb-2">
                        Do you have renter insurance?
                    </label>
                    <select
                        id="hasRenterInsurance"
                        name="hasRenterInsurance"
                        onChange={handleChange}
                        value={formData.hasRenterInsurance}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    >
                        <option value="">Select Option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                    {errors.hasRenterInsurance && <p className="text-red-500 text-xs italic">{errors.hasRenterInsurance}</p>}
                </div>

                {/* Insurance Company */}
                {formData.hasRenterInsurance === 'yes' && (
                    <div className="mb-4">
                        <label htmlFor="insuranceCompany" className="block text-gray-700 text-sm font-bold mb-2">
                            Insurance Company
                        </label>
                        <input
                            id="insuranceCompany"
                            name="insuranceCompany"
                            type="text"
                            onChange={handleChange}
                            value={formData.insuranceCompany}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                        />
                        
                    </div>
                    
                )}

                {/* Personal Property Coverage */}
                
<div className="mb-4">
                <label htmlFor="personalPropertyCoverage" className="block text-gray-700 text-sm font-bold mb-2">
                    Personal Property Coverage Amount
                </label>
                <select
                    id="personalPropertyCoverage"
                    name="personalPropertyCoverage"
                    onChange={handleChange}
                    value={formData.personalPropertyCoverage}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                >
                    <option value="">Select Option</option>
                    <option value="5000">5k</option>
                    <option value="10000">10k</option>
                    <option value="15000">15k</option>
                    <option value="20000">20k</option>
                    <option value="25000">25k</option>
                    <option value="30000">30k</option>
                    <option value="35000">35k</option>
                    <option value="40000">40k</option>
                    <option value="45000">45k</option>
                    <option value="50000">50k</option>
                    <option value="55000">55k</option>
                    <option value="60000">60k</option>
                    <option value="65000">65k</option>
                    <option value="70000">70k</option>
                    <option value="75000">75k</option>
                    
                </select>
                {errors.personalPropertyCoverage && <p className="text-red-500 text-xs italic">{errors.personalPropertyCoverage}</p>}
            </div>
                {/* Liability Coverage */}
                <div className="mb-4">
                    <label htmlFor="liabilityCoverage" className="block text-gray-700 text-sm font-bold mb-2">
                        Choose Liability Coverage Amount
                    </label>
                    <select
                        id="liabilityCoverage"
                        name="liabilityCoverage"
                        onChange={handleChange}
                        value={formData.liabilityCoverage}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    >
                        <option value="">Select Option</option>
                        <option value="25000">25k</option>
                        <option value="50000">50k</option>
                        <option value="100000">100k</option>
                        <option value="200000">200k</option>
                        <option value="300000">300k</option>
                    </select>
                    {errors.liabilityCoverage && <p className="text-red-500 text-xs italic">{errors.liabilityCoverage}</p>}
                </div>

                {/* Water Backup Coverage */}
                <div className="mb-4">
                    <label htmlFor="waterBackupCoverage" className="block text-gray-700 text-sm font-bold mb-2">
                        Do you want to add water backup coverage?
                    </label>
                    <select
                        id="waterBackupCoverage"
                        name="waterBackupCoverage"
                        onChange={handleChange}
                        value={formData.waterBackupCoverage}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    >
                        <option value="">Select Option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                    {errors.waterBackupCoverage && <p className="text-red-500 text-xs italic">{errors.waterBackupCoverage}</p>}
                </div>

                {/* Submit Button */}
                <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
                    Next
                </button>
            </form>
       
    );
};

export default CoverageSectionForm;
