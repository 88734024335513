import React,{useState} from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
const Navbar = ({onReset}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleLogin = () => {
      window.location.href = 'https://birimoinsuranceagency.sharepoint.com/sites/BIAEmployees/SitePages/LearningTeamHome.aspx';
    };
    
    const handleAdminLogin = () => {
      window.location.href = '/admin';
    };
    
    const navigate=useNavigate();
    const location = useLocation();
    const navabar=()=>{
      navigate('/')
      if (location.pathname !== '/ContactUS' && location.pathname !== '/AboutUS') {
        onReset();
    }
    }
  return (
    <nav className="bg-navcolor text-white p-4 w-full">
    <div className=" flex items-center justify-between w-full px-5">
      {/* Logo or brand name */}
      <div className="text-xl font-semibold" onClick={navabar}>
        <img src='/images/logoremovebg.png' className='h-[5vh] w-[50px]'></img>
      </div>

      {/* Navigation Links */}
      <div className="hidden md:flex items-center space-x-4  w-[30%] justify-end">
      <h1 class="text-3xl font-bold text-white">Birimo Insurance Agency</h1>

      </div>

      {/* Right side items */}
      <div className="flex items-center space-x-4">
        <div className="cursor-pointer md:mr-5 lg:mr-5">
            <div className='flex flex-row items-center justify-between' onClick={handleAdminLogin}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                </svg>
                <h1 className='ml-2 font-semibold hidden md:inline-flex lg:inline-flex'>Admin</h1>
            </div>
        </div>

        <div className=" cursor-pointer md:mr-10 lg:mr-10 ">
            <div className='flex flex-row items-center justify-between mr-8' onClick={handleLogin}>
                <img src='/images/Profile2.png' className='h-7 '/>
                <h1 className='ml-2 font-semibold hidden md:inline-flex lg:inline-flex' >Employees</h1></div>
          
        </div>
        <button className=" hidden md:inline-flex lg:inline-flex text-white focus:outline-none"  onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
          
          </button>
        {/* Mobile menu button */}
        <button className="md:hidden text-white focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
          </button>
        </div>

    {/* SideBar starts from here */}
        <div
        className={`fixed top-0 right-0 w-[300px] h-[100vh] bg-navcolor text-white transform ease-in-out duration-300 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Close Button */}
        <div className='w-full flex items-center justify-end pr-10 mt-[20%]'>
        <button
          onClick={() => setIsMenuOpen(false)}
          className="text-white focus:outline-none font-semibold"
          
        >
          X
        </button>
        </div>
        
        {/* Menu Content */}
        <nav className='mt-[8%]'>
          {/* Menu items */}
          <a href="/" className="block p-4 hover:bg-blue-300 font-bold">Home</a>
          <a href="/AboutUS" className="block p-4 hover:bg-blue-300 font-bold">About Us</a>
          <a href="/ContactUS" className="block p-4 hover:bg-blue-300 font-bold">Contact</a>
          {/* Removed Admin Login from sidebar since it's now in the navbar */}
          
          {/* ... other menu items */}
        </nav>
      </div>
      </div>
    </nav>
  );
};
  

export default Navbar
