import React, { useEffect, useState } from 'react';
import { db } from '../Firebase'; // Ensure this points to your Firebase configuration file
import { collection, getDocs, doc, deleteDoc, query, orderBy, onSnapshot, updateDoc } from 'firebase/firestore';
import { formatToUSDate } from '../utils/dateUtils';
import RentersDetailView from './RentersDetailView';
import jsPDF from 'jspdf';

const RentersCards = () => {
    const [renters, setRenters] = useState([]);
    const [selectedRenter, setSelectedRenter] = useState(null);

    useEffect(() => {
        const rentersQuery = query(collection(db, "Renters"), orderBy("submittedAt", "desc"));
        const unsubscribe = onSnapshot(rentersQuery, (snapshot) => {
            // Check for new documents and send email notifications
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const data = change.doc.data();
                    const submittedAt = data.submittedAt?.toDate();
                    // Check if the document was created in the last minute
                    if (submittedAt && (new Date().getTime() - submittedAt.getTime()) < 60000) {
                        // Send email notification for new submissions
                        sendEmailNotification(data);
                    }
                }
            });

            // Update state with all documents
            const rentersData = snapshot.docs.map(doc => ({
                id: doc.id,
                seen: false, // Default to unseen
                ...doc.data(),
            }));
            setRenters(rentersData);
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, []);

    const sendEmailNotification = async (formData) => {
        try {
            const emailContent = {
                subject: "New Renters Insurance Application",
                timestamp: new Date().toLocaleString(),
                personalInfo: {
                    name: `${formData.firstName} ${formData.lastName}`,
                    email: formData.email,
                    phone: formData.phone,
                    rentalAddress: formData.rentalAddress,
                    mailingAddress: formData.sameAsRental ? formData.rentalAddress : formData.mailingAddress
                },
                coverageInfo: {
                    hasRenterInsurance: formData.coverageData?.hasRenterInsurance,
                    insuranceCompany: formData.coverageData?.insuranceCompany || 'N/A',
                    personalPropertyCoverage: `$${formData.coverageData?.personalPropertyCoverage}`,
                    liabilityCoverage: `$${formData.coverageData?.liabilityCoverage}`,
                    waterBackupCoverage: formData.coverageData?.waterBackupCoverage
                },
                occupants: formData.occupants?.map((occupant, index) => ({
                    number: index + 1,
                    name: `${occupant.firstName} ${occupant.lastName}`
                })) || []
            };

            // Send immediately without waiting for response
            fetch('https://backend-birimo.vercel.app/api/notify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    formType: "Renters",
                    formData: emailContent
                })
            }).catch(error => console.error('Error sending notification:', error));
        } catch (error) {
            console.error('Error preparing notification:', error);
        }
    };

    const markAsSeen = async (renterId) => {
        try {
            await updateDoc(doc(db, "Renters", renterId), {
                seen: true,
                seenAt: new Date()
            });
        } catch (error) {
            console.error("Error marking as seen:", error);
        }
    };

    const handleCardClick = (renter) => {
        setSelectedRenter(renter);
        if (!renter.seen) {
            markAsSeen(renter.id);
        }
    };

    const deleteRenter = async (e, renterId) => {
        e.stopPropagation();
        try {
            await deleteDoc(doc(db, "Renters", renterId));
            setRenters(renters.filter(renter => renter.id !== renterId));
        } catch (error) {
            console.error("Error deleting renter:", error);
        }
    };

    const renderPersonalInfo = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Personal Info</h4>
            <p className="text-sm">First Name: {renter.firstName}</p>
            <p className="text-sm">Last Name: {renter.lastName}</p>
            <p className="text-sm">Email: {renter.email}</p>
            <p className="text-sm">Rental Address: {renter.rentalAddress}</p>
            <p className="text-sm">Mailing Address: {renter.sameAsRental ? renter.rentalAddress : renter.mailingAddress}</p>
            <p className="text-sm">Same As Rental: {renter.sameAsRental ? 'Yes' : 'No'}</p>
        </div>
    );

    const renderCoverageData = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Coverage Data</h4>
            <p className="text-sm">Has Renter Insurance: {renter.coverageData.hasRenterInsurance}</p>
            <p className="text-sm">Insurance Company: {renter.coverageData.insuranceCompany || 'Not Provided'}</p>
            <p className="text-sm">Liability Coverage: {renter.coverageData.liabilityCoverage}</p>
            <p className="text-sm">Personal Property Coverage: {renter.coverageData.personalPropertyCoverage}</p>
            <p className="text-sm">Policy Effective Date: {formatToUSDate(renter.coverageData.policyEffectiveDate)}</p>
            <p className="text-sm">Water Backup Coverage: {renter.coverageData.waterBackupCoverage}</p>
        </div>
    );

    const renderOccupants = (renter) => (
        <div className="mb-4">
            <h4 className="text-lg font-semibold">Occupants</h4>
            {renter.occupants.map((occupant, index) => (
                <div key={index} className="mt-2">
                    <p className="text-sm">First Name: {occupant.firstName}</p>
                    <p className="text-sm">Last Name: {occupant.lastName}</p>
                </div>
            ))}
        </div>
    );

    const formatSubmissionDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return formatToUSDate(timestamp.toDate());
    };

    // Function to generate and download PDF of renter details
    const generatePDF = (e, renter) => {
        e.stopPropagation(); // Prevent card click event
        
        const doc = new jsPDF();
        let yPos = 20;
        const lineHeight = 7;
        const margin = 15;
        
        // Title
        doc.setFontSize(16);
        doc.text('Renters Insurance Application', margin, yPos);
        yPos += lineHeight * 2;
        
        // Personal Details
        doc.setFontSize(14);
        doc.text('Personal Information', margin, yPos);
        yPos += lineHeight;
        doc.setFontSize(10);
        
        // Add personal information
        if (renter.firstName) {
            doc.text(`Name: ${renter.firstName} ${renter.lastName || ''}`, margin, yPos);
            yPos += lineHeight;
        }
        
        if (renter.email) {
            doc.text(`Email: ${renter.email}`, margin, yPos);
            yPos += lineHeight;
        }
        
        if (renter.phone) {
            doc.text(`Phone: ${renter.phone}`, margin, yPos);
            yPos += lineHeight;
        }
        
        if (renter.rentalAddress) {
            doc.text(`Rental Address: ${renter.rentalAddress}`, margin, yPos);
            yPos += lineHeight;
        }
        
        if (renter.mailingAddress && !renter.sameAsRental) {
            doc.text(`Mailing Address: ${renter.mailingAddress}`, margin, yPos);
            yPos += lineHeight;
        }
        
        // Coverage Information
        if (renter.coverageData) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Coverage Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            const coverage = renter.coverageData;
            
            if (coverage.hasRenterInsurance) {
                doc.text(`Has Renter Insurance: ${coverage.hasRenterInsurance}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (coverage.insuranceCompany) {
                doc.text(`Insurance Company: ${coverage.insuranceCompany}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (coverage.personalPropertyCoverage) {
                doc.text(`Personal Property Coverage: $${coverage.personalPropertyCoverage}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (coverage.liabilityCoverage) {
                doc.text(`Liability Coverage: $${coverage.liabilityCoverage}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (coverage.waterBackupCoverage) {
                doc.text(`Water Backup Coverage: ${coverage.waterBackupCoverage}`, margin, yPos);
                yPos += lineHeight;
            }
            
            if (coverage.policyEffectiveDate) {
                doc.text(`Policy Effective Date: ${formatToUSDate(coverage.policyEffectiveDate)}`, margin, yPos);
                yPos += lineHeight;
            }
        }
        
        // Occupants Information
        if (renter.occupants && renter.occupants.length > 0) {
            yPos += lineHeight;
            doc.setFontSize(14);
            doc.text('Occupants Information', margin, yPos);
            yPos += lineHeight;
            doc.setFontSize(10);
            
            renter.occupants.forEach((occupant, index) => {
                doc.text(`Occupant ${index + 1}: ${occupant.firstName} ${occupant.lastName}`, margin, yPos);
                yPos += lineHeight;
            });
        }
        
        // Add submission timestamp
        if (renter.submittedAt) {
            yPos += lineHeight;
            doc.text(`Submission Date: ${formatSubmissionDate(renter.submittedAt)}`, margin, yPos);
        }
        
        // Generate filename and save
        const filename = `${renter.firstName || 'Renter'}_${renter.lastName || ''}_Renters_Insurance.pdf`;
        doc.save(filename);
    };

    const renderSummaryCard = (renter) => (
        <div 
            key={renter.id} 
            className={`bg-white rounded-lg shadow-md p-6 cursor-pointer hover:shadow-lg transition-shadow relative ${
                !renter.seen ? 'border-l-4 border-blue-500' : ''
            }`}
            onClick={() => handleCardClick(renter)}
        >
            {!renter.seen && (
                <div className="absolute top-2 right-2">
                    <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                        New
                    </span>
                </div>
            )}
            
            <div className="flex justify-between items-start">
                <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                        {renter.firstName} {renter.lastName}
                    </h3>
                    <p className="text-sm text-gray-500">{renter.email}</p>
                    <p className="text-sm text-gray-500">
                        Coverage: ${renter.coverageData?.personalPropertyCoverage || 'N/A'}
                    </p>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={(e) => generatePDF(e, renter)}
                        className="text-blue-500 hover:text-blue-700"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </button>
                    <button
                        onClick={(e) => deleteRenter(e, renter.id)}
                        className="text-red-500 hover:text-red-700"
                    >
                        <img src='/images/delete.png' className="w-[20px] h-[20px]" alt="Delete" />
                    </button>
                </div>
            </div>
            
            <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                <div>
                    <span className="text-gray-500">Occupants:</span>
                    <span className="ml-2 text-gray-900">{renter.occupants?.length || 0}</span>
                </div>
                <div>
                    <span className="text-gray-500">Status:</span>
                    <span className="ml-2 text-green-600">Active</span>
                </div>
            </div>
            
            <div className="mt-4 text-xs text-gray-500">
                Submitted: {formatToUSDate(renter.submittedAt?.toDate())}
            </div>
        </div>
    );

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {renters.map(renderSummaryCard)}
            </div>

            {selectedRenter && (
                <RentersDetailView 
                    data={selectedRenter} 
                    onClose={() => setSelectedRenter(null)} 
                />
            )}
        </>
    );
};

export default RentersCards;


