import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Firebase';

const TermsAndConditions = ({ onSubmit, formData }) => {
    const [agreed, setAgreed] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAgreementChange = (e) => {
        setAgreed(e.target.checked);
    };

    // Function to send email notification using our backend API
    const sendEmailNotificationAPI = async (formData) => {
        try {
            // Create a simplified version of the form data with ONLY essential info
            const simplifiedFormData = {
                firstName: formData.firstName || '',
                lastName: formData.lastName || '',
                email: formData.email || '',
                phone: formData.phone || '',
                submittedAt: new Date()
            };
            
            // Log what we're sending to verify
            console.log('Sending simplified data to email API:', simplifiedFormData);
            
            const response = await fetch('https://backend-birimo.vercel.app/api/send-auto-notification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ formData: simplifiedFormData }),
            });
            
            const data = await response.json();
            
            if (data.success) {
                console.log('Email notification sent successfully via API');
                return true;
            } else {
                console.error('Failed to send email notification via API:', data.message);
                return false;
            }
        } catch (error) {
            console.error('Error sending email notification via API:', error);
            return false;
        }
    };

    // Legacy Firebase email function (keeping as fallback)
    const sendEmailNotification = async (formData) => {
        try {
            await addDoc(collection(db, "mail"), {
                to: ["birimo.insurance@gmail.com"],
                message: {
                    subject: "New Personal Auto Insurance Quote Request",
                    html: `
                        <h2>New Personal Auto Insurance Quote Request</h2>
                        <h3>Personal Information:</h3>
                        <p>Name: ${formData.firstName} ${formData.lastName}</p>
                        <p>Email: ${formData.email}</p>
                        <p>Phone: ${formData.phone}</p>
                        <p>Address: ${formData.address}</p>
                        <p>Address Duration: ${formData.addressDuration}</p>
                        <p>Occupation: ${formData.occupation}</p>
                        <p>Education Level: ${formData.educationLevel}</p>
                        
                        <h3>Car Details:</h3>
                        ${formData.carDetails.map((car, index) => `
                            <p>Car ${index + 1}:</p>
                            <p>Make: ${car.carMake}</p>
                            <p>Model: ${car.carModel}</p>
                            <p>Year: ${car.carYear}</p>
                            <p>VIN: ${car.vinNumber}</p>
                            <p>Co-signer: ${car.coSigner}</p>
                            <p>Vehicle Duration: ${car.vehicleDuration}</p>
                            <p>Vehicle Use: ${car.vehicleUse}</p>
                        `).join('')}
                        
                        <h3>Coverage Information:</h3>
                        <p>Has Insurance: ${formData.coverageData.hasInsurance}</p>
                        <p>Prior Insurance Carrier: ${formData.coverageData.priorInsuranceCarrier}</p>
                        <p>Insurance Name: ${formData.coverageData.insuranceName}</p>
                        <p>Insurance Duration: ${formData.coverageData.insuranceDuration}</p>
                        
                        <h3>Household Information:</h3>
                        <p>Total Household Members: ${formData.totalHouseholdMembers}</p>
                    `,
                },
            });
            return true;
        } catch (error) {
            console.error("Error sending email notification:", error);
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (agreed) {
            setIsSubmitting(true);
            
            // Clean up the form data before submission
            const cleanFormData = {
                ...formData,
                submittedAt: new Date(),
                // Clean up nested objects
                coverageData: Object.fromEntries(
                    Object.entries(formData.coverageData || {}).filter(([_, value]) => value !== '')
                ),
                // Clean up arrays
                carDetails: (formData.carDetails || []).map(car => 
                    Object.fromEntries(Object.entries(car).filter(([_, value]) => value !== ''))
                ),
                householdMembers: (formData.householdMembers || []).map(member => 
                    Object.fromEntries(Object.entries(member).filter(([_, value]) => value !== ''))
                )
            };

            try {
                // Add to insuranceForms collection (for Personal Auto)
                const docRef = await addDoc(collection(db, "insuranceForms"), cleanFormData);
                console.log("Document written with ID: ", docRef.id);
                
                // Try to send email via our new API
                const apiEmailSuccess = await sendEmailNotificationAPI(cleanFormData);
                
                // If API email fails, try the legacy Firebase method as fallback
                if (!apiEmailSuccess) {
                    console.log("API email failed, trying Firebase email as fallback");
                    await sendEmailNotification(cleanFormData);
                }
                
                // Call onSubmit to complete the form process
                onSubmit();
            } catch (error) {
                console.error("Error submitting form: ", error);
                // Try to send email via Firebase as a fallback
                try {
                    await sendEmailNotification(cleanFormData);
                } catch (emailError) {
                    console.error("Error sending email notification: ", emailError);
                }
                // Still call onSubmit to complete the form process even if there was an error
                onSubmit();
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
            <h1 className="text-2xl font-bold mb-6 text-center">Terms and Conditions</h1>
            
            {/* Terms and Conditions Text */}
            <ul className="list-disc ml-6 mb-4">
                <li className='mb-5'>I consent to receive SMS/calls/email at any time offering quotes from Birimo Insurance Agency LLC.</li>
                <li className='mb-5'>We collect data for insurance purposes.</li>
                <li className='mb-5'>We don't and will not sell your personal info.</li>
                <li className='mb-5'>We will share the data we collect with the carriers we work with to get a quote for you.</li>
            </ul>

            {/* Agreement Checkbox */}
            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        checked={agreed}
                        onChange={handleAgreementChange}
                        className="form-checkbox text-teal-600"
                    />
                    <span className="ml-2">I agree to the terms and conditions</span>
                </label>
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                disabled={!agreed || isSubmitting}
                className={`bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4 ${!agreed || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
        </form>
    );
};

export default TermsAndConditions;
