// Format date string to US format (MM/DD/YYYY)
export const formatToUSDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

// Get today's date in YYYY-MM-DD format for date inputs
export const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
}; 