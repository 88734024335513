import React from 'react';
import { formatToUSDate } from '../utils/dateUtils';

const RentersDetailView = ({ data, onClose }) => {
  const renderSection = (title, content) => (
    <div className="bg-white rounded-lg shadow mb-6 p-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {content}
      </div>
    </div>
  );

  const renderField = (label, value) => (
    <div className="mb-2">
      <span className="text-sm font-medium text-gray-500">{label}: </span>
      <span className="text-sm text-gray-900">{value}</span>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-md bg-gray-100">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-900">Renter Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <span className="text-2xl">&times;</span>
          </button>
        </div>

        <div className="max-h-[70vh] overflow-y-auto">
          {/* Personal Information */}
          {renderSection("Personal Information", (
            <>
              {renderField("Name", `${data.firstName} ${data.lastName}`)}
              {renderField("Email", data.email)}
              {renderField("Phone", data.phone)}
              {renderField("Rental Address", data.rentalAddress)}
              {renderField("Mailing Address", data.sameAsRental ? data.rentalAddress : data.mailingAddress)}
            </>
          ))}

          {/* Coverage Information */}
          {data.coverageData && renderSection("Coverage Information", (
            <>
              {renderField("Has Renter Insurance", data.coverageData.hasRenterInsurance)}
              {renderField("Insurance Company", data.coverageData.insuranceCompany || 'N/A')}
              {renderField("Personal Property Coverage", `$${data.coverageData.personalPropertyCoverage}`)}
              {renderField("Liability Coverage", `$${data.coverageData.liabilityCoverage}`)}
              {renderField("Water Backup Coverage", data.coverageData.waterBackupCoverage)}
              {renderField("Policy Effective Date", formatToUSDate(data.coverageData.policyEffectiveDate))}
            </>
          ))}

          {/* Occupants Information */}
          {data.occupants && data.occupants.length > 0 && renderSection("Additional Occupants", (
            <>
              {data.occupants.map((occupant, index) => (
                <div key={index} className="border-b pb-2 mb-2 last:border-b-0">
                  {renderField(`Occupant ${index + 1}`, `${occupant.firstName} ${occupant.lastName}`)}
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RentersDetailView; 