import React, { useState } from 'react';

const CoverageSection = ({ coverageData, setCoverageData, onSubmit }) => {
   
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCoverageData({ ...coverageData, [name]: value });
    };

    const validateCoverageData = () => {
        let tempErrors = {};
        let isValid = true;

        // Basic validation for required fields
        if (!coverageData.hasInsurance) {
            tempErrors.hasInsurance = "Insurance status is required";
            isValid = false;
        }

        if (coverageData.hasInsurance === 'yes') {
            if (!coverageData.priorInsuranceCarrier) {
                tempErrors.priorInsuranceCarrier = 'Prior insurance carrier is required';
                isValid = false;
            }
            if (!coverageData.insuranceDuration) {
                tempErrors.insuranceDuration = 'Insurance duration is required';
                isValid = false;
            }
        }

        // Required fields for all cases
        if (!coverageData.bodyInjuryPropertyDamage) {
            tempErrors.bodyInjuryPropertyDamage = 'Selection is required';
            isValid = false;
        }
        if (!coverageData.comprehensiveCollisionDeductible) {
            tempErrors.comprehensiveCollisionDeductible = 'Selection is required';
            isValid = false;
        }
        if (!coverageData.fullGlassCoverage) {
            tempErrors.fullGlassCoverage = "Full glass coverage selection is required";
            isValid = false;
        }
        if (!coverageData.needTowing) {
            tempErrors.needTowing = "Towing service selection is required";
            isValid = false;
        }
        if (!coverageData.needRentalCar) {
            tempErrors.needRentalCar = "Rental car service selection is required";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        // Clean up empty fields before submission
        const cleanedCoverageData = Object.fromEntries(
            Object.entries(coverageData).filter(([_, value]) => value !== '')
        );

        if (validateCoverageData()) {
            onSubmit(cleanedCoverageData);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg  overflow-y-auto form-scrollbar form-container">
            <h1 className="text-2xl font-bold mb-6 text-center">Coverage Section</h1>

            {/* Has Insurance */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hasInsurance">
                    Do you have insurance?
                </label>
                <select
                    id="hasInsurance"
                    name="hasInsurance"
                    value={coverageData.hasInsurance}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.hasInsurance && <p className="text-red-500 text-xs italic">{errors.hasInsurance}</p>}
            </div>

            {/* Insurance Name and Duration */}
            {coverageData.hasInsurance === 'yes' && (
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="priorInsuranceCarrier">
                            Prior Auto Insurance Carrier
                        </label>
                        <select
                            id="priorInsuranceCarrier"
                            name="priorInsuranceCarrier"
                            value={coverageData.priorInsuranceCarrier || ''}
                            onChange={handleChange}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="">Select Prior Insurance Carrier</option>
                            <option value="AAA/AUTO CLUB">AAA/AUTO CLUB</option>
                            <option value="ACUITY">ACUITY</option>
                            <option value="AIG: GRANITE STATE INS CO">AIG: GRANITE STATE INS CO</option>
                            <option value="AIG: OTHER">AIG: OTHER</option>
                            <option value="ALLIED">ALLIED</option>
                            <option value="ALLSTATE">ALLSTATE</option>
                            <option value="AMERICAN FAMILY">AMERICAN FAMILY</option>
                            <option value="AMERICAN NATIONAL">AMERICAN NATIONAL</option>
                            <option value="AMERIPRISE FINANCIAL GRP">AMERIPRISE FINANCIAL GRP</option>
                            <option value="AMICA">AMICA</option>
                            <option value="AUSTIN MUTUAL INS CO">AUSTIN MUTUAL INS CO</option>
                            <option value="AUTO-OWNERS">AUTO-OWNERS</option>
                            <option value="CHUBB">CHUBB</option>
                            <option value="CINCINNATI FINANCIAL">CINCINNATI FINANCIAL</option>
                            <option value="COMPLIANT WITHOUT INSURANCE">COMPLIANT WITHOUT INSURANCE</option>
                            <option value="COUNTRY INS">COUNTRY INS</option>
                            <option value="CWI - DEPLOYED MILITARY">CWI - DEPLOYED MILITARY</option>
                            <option value="DAIRYLAND">DAIRYLAND</option>
                            <option value="ENCOMPASS">ENCOMPASS</option>
                            <option value="FARMERS">FARMERS</option>
                            <option value="FOREMOST">FOREMOST</option>
                            <option value="GEICO">GEICO</option>
                            <option value="GENERAL CASUALTY">GENERAL CASUALTY</option>
                            <option value="GMAC">GMAC</option>
                            <option value="GRANGE MUTUAL">GRANGE MUTUAL</option>
                            <option value="GRINNELL">GRINNELL</option>
                            <option value="HARLEYSVILLE">HARLEYSVILLE</option>
                            <option value="HARTFORD">HARTFORD</option>
                            <option value="HORACE MANN">HORACE MANN</option>
                            <option value="INFINITY">INFINITY</option>
                            <option value="KEMPER">KEMPER</option>
                            <option value="LIBERTY MUTUAL">LIBERTY MUTUAL</option>
                            <option value="MAIN STREET AMERICA GRP">MAIN STREET AMERICA GRP</option>
                            <option value="MENDOTA">MENDOTA</option>
                            <option value="METLIFE">METLIFE</option>
                            <option value="MIDWEST FAMILY MUTUAL INS CO">MIDWEST FAMILY MUTUAL INS CO</option>
                            <option value="NATIONAL FARMERS UNION">NATIONAL FARMERS UNION</option>
                            <option value="NATIONWIDE">NATIONWIDE</option>
                            <option value="NORTH STAR MUTUAL INS CO">NORTH STAR MUTUAL INS CO</option>
                            <option value="OTHER STANDARD">OTHER STANDARD</option>
                            <option value="OTHER NON-STANDARD">OTHER NON-STANDARD</option>
                            <option value="PROGRESSIVE">PROGRESSIVE</option>
                            <option value="QBE INS">QBE INS</option>
                            <option value="SAFECO">SAFECO</option>
                            <option value="SECURA INS">SECURA INS</option>
                            <option value="SELECTIVE INS">SELECTIVE INS</option>
                            <option value="STATE AUTO">STATE AUTO</option>
                            <option value="STATE FARM">STATE FARM</option>
                            <option value="TITAN">TITAN</option>
                            <option value="TRAVELERS">TRAVELERS</option>
                            <option value="UNITRIN">UNITRIN</option>
                            <option value="USAA">USAA</option>
                            <option value="VICTORIA">VICTORIA</option>
                            <option value="WEST BEND INS">WEST BEND INS</option>
                            <option value="WESTERN NATIONAL">WESTERN NATIONAL</option>
                        </select>
                        {errors.priorInsuranceCarrier && <p className="text-red-500 text-xs italic">{errors.priorInsuranceCarrier}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="insuranceDuration">
                            How long have you been with them?
                        </label>
                        <select
                            id="insuranceDuration"
                            name="insuranceDuration"
                            value={coverageData.insuranceDuration}
                            onChange={handleChange}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="">Select Duration</option>
                            <option value="less_than_1_month">Less than 1 month</option>
                            <option value="less_than_3_months">Less than 3 months</option>
                            <option value="6_months">6 months</option>
                            <option value="more_than_6_months">More than 6 months</option>
                        </select>
                        {errors.insuranceDuration && <p className="text-red-500 text-xs italic">{errors.insuranceDuration}</p>}
                    </div>
                </>
            )}

            {/* Body Injury and Property Damage */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bodyInjuryPropertyDamage">
                    Body Injury and Property Damage
                </label>
                <select
                    id="bodyInjuryPropertyDamage"
                    name="bodyInjuryPropertyDamage"
                    value={coverageData.bodyInjuryPropertyDamage}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="30_60_10">30/60/10</option>
                    <option value="30_60_25">30/60/25</option>
                    <option value="50_100_25">50/100/25</option>
                    <option value="50_100_50">50/100/50</option>
                    <option value="100_300_50">100/300/50</option>
                    <option value="100_300_100">100/300/100</option>
                </select>
                {errors.bodyInjuryPropertyDamage && <p className="text-red-500 text-xs italic">{errors.bodyInjuryPropertyDamage}</p>}
            </div>

            {/* Comprehensive and Collision Deductible */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comprehensiveCollisionDeductible">
                    Comprehensive and Collision Deductible
                </label>
                <select
                    id="comprehensiveCollisionDeductible"
                    name="comprehensiveCollisionDeductible"
                    value={coverageData.comprehensiveCollisionDeductible}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Deductible</option>
                    <option value="No Deductible">No Deductible</option>
                    <option value="500">500</option>
                    <option value="750">750</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value="2500">2500</option>
                </select>
                {errors.comprehensiveCollisionDeductible && <p className="text-red-500 text-xs italic">{errors.comprehensiveCollisionDeductible}</p>}
            </div>

            {/* Full Glass Coverage */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullGlassCoverage">
                    Do you want to add full glass coverage?
                </label>
                <select
                    id="fullGlassCoverage"
                    name="fullGlassCoverage"
                    value={coverageData.fullGlassCoverage}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.fullGlassCoverage && <p className="text-red-500 text-xs italic">{errors.fullGlassCoverage}</p>}
            </div>

            {/* Towing */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="needTowing">
                    Do you need towing?
                </label>
                <select
                    id="needTowing"
                    name="needTowing"
                    value={coverageData.needTowing}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.needTowing && <p className="text-red-500 text-xs italic">{errors.needTowing}</p>}
            </div>

                        {/* Rental Car */}
                        <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="needRentalCar">
                    Do you need to add rental car coverage?
                </label>
                <select
                    id="needRentalCar"
                    name="needRentalCar"
                    value={coverageData.needRentalCar}
                    onChange={handleChange}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                {errors.needRentalCar && <p className="text-red-500 text-xs italic">{errors.needRentalCar}</p>}
            </div>

            {/* Submit Button */}
            <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out mt-4">
                Next
            </button>
        </form>
    );
};

export default CoverageSection;
